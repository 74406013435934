import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Loading">
          <h1 className="LoadHeader"> Rongomaipapa Marae </h1>
          <h5 className="LoadSubtitle"> In Development </h5>
          <div className="SocialBar">
          <a href = "mailto: marae@rongomaipapa.com">
            <FontAwesomeIcon icon ={faEnvelope} className="Icon" />
          </a>
            <a href='https://www.facebook.com/groups/1511408722458006'>
              <FontAwesomeIcon icon ={faFacebook} className="Icon" />
            </a>
            </div>
        </div>
      </header>
    </div>
  );
}

export default App;